import Vue from 'vue'
import Vuex from 'vuex'
import {
  activeLayout,
  defaultPage,
  isLoggedIn,
  updateSession,
  logout,
  setActiveSite,
  setSessionLength,
  updateSessionTimeout,
  clearSessionTimeout,
  updateServiceRequest,
  hasPermissions
} from './vuex-actions'
import { STAFF, MANAGER, OSR, CLIENT, TEAM } from './roles'
import {
  CLIENT_LAYOUT,
  OSR_LAYOUT,
  ADMIN,
  DEFAULT_CLIENT_PAGE,
  DEFAULT_ADMIN_PAGE
} from './layouts'
import Axios from './axios'
import VuexPersist from 'vuex-persist'
import router from './router'
import { DialogProgrammatic as Dialog } from 'buefy'
const vuexPersist = new VuexPersist({
  key: 'bcc-dashboard',
  storage: localStorage
})

Vue.use(Vuex)
let defaultMsLength = 5 * 60 * 1000
let msBuffer = 2 * 60 * 1000
let dialogPrompt

async function logOut () {
  let route = `/session/logout`
  await Axios.post(route)
  store.commit(logout)
  let nav = { name: 'Login' }
  nav.query = { fromPath: router.history.current.fullPath }
  await router.push(nav).then(_ => window.location.reload()).catch(err => { this.handleApiErr(err, null, null, true) })
  if (dialogPrompt) {
    dialogPrompt.close()
    dialogPrompt = undefined
  }
}

function launchSessionExpiredPrompt () {
  if (!store.getters.isLoggedIn || dialogPrompt) {
    return
  }

  // Disable select portions of the screen
  let menuControl = document.querySelector('.menu-control-top')
  let collapseControl = document.querySelector('.menu-control-bottom')

  if (menuControl) {
    menuControl.style.display = 'none'
  }

  if (collapseControl) {
    collapseControl.style.display = 'none'
  }

  let secondsLeft = Math.round(msBuffer / 1000)
  let countdownInterval

  dialogPrompt = Dialog.confirm({
    'trap-focus': true,
    title: 'Session expiring soon',
    message: `As a security precaution, if there is no additional activity in your session, your session will end in <b id='countdown'>${secondsLeft}</b> seconds and you will be brought to the login page.<br><br>If you are still working in your session, choose <b>Continue</b> to remain logged in.`,
    confirmText: 'Continue',
    cancelText: 'Logout',
    type: 'is-accent',
    hasIcon: false,
    onCancel: async function () {
      clearInterval(countdownInterval)
      try {
        await logOut()
      } finally {
        if (dialogPrompt) {
          dialogPrompt.close()
          dialogPrompt = undefined
        }
      }

      if (menuControl) {
        menuControl.style.display = 'flex'
      }

      if (collapseControl) {
        collapseControl.style.display = 'flex'
      }
    },
    onConfirm: async function () {
      clearInterval(countdownInterval)
      try {
        let route = `/session/refresh`
        await Axios.get(route)
        // timeouts are updated via axios /api/ success interceptor
      } catch (e) {
        // Means we got a 401 error most likely. Logout the user.
        await logOut()
      } finally {
        if (dialogPrompt) {
          dialogPrompt.close()
          dialogPrompt = undefined
        }

        if (menuControl) {
          menuControl.style.display = 'flex'
        }

        if (collapseControl) {
          collapseControl.style.display = 'flex'
        }
      }
    }
  })

  // Start the countdown
  countdownInterval = setInterval(() => {
    secondsLeft--
    const countdownElement = document.getElementById('countdown')
    if (countdownElement) {
      countdownElement.textContent = secondsLeft
    }
    if (secondsLeft <= 0) {
      clearInterval(countdownInterval)
      logOut()
    }
  }, 1000)
}

window.launchSessionExpiredPrompt = launchSessionExpiredPrompt

function getMsBuffer (msLength) {
  let buf = msBuffer
  if (buf >= msLength) {
    buf = 30 * 1000
  }
  return buf
}

export const store = new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: {
    timeoutPrompt: null,
    timeout: null,
    clientSession: {
      siteId: 0,
      userId: 0,
      userRole: CLIENT,
      isMFAEnabled: false,
      isMFAValid: false,
      institutionId: 0,
      msLength: defaultMsLength
    },
    activeSite: {
      id: 0,
      name: '',
      displayName: '',
      url: '',
      logoUrl: '',
      linkedUrl: ''
    },
    serviceRequest: ''
    // activeLayout: CLIENT_LAYOUT
  },
  getters: {
    [hasPermissions]: ({ state }, permissions = []) => {
      return (
        state.clientSession &&
        state.clientSession.permissions.filter((permission) =>
          permissions.includes(permission)
        ).length > 1
      )
    },
    // ...mapGetters()
    [activeLayout]: (state) => {
      if (!state.clientSession) {
        return CLIENT_LAYOUT
      }

      if (
        state.clientSession &&
        [STAFF, MANAGER].includes(state.clientSession.userRole)
      ) {
        return ADMIN
      }
      if (
        state.clientSession.userRole === OSR ||
        state.clientSession.userRole === TEAM
      ) {
        return OSR_LAYOUT
      }
      if (state.clientSession.userRole === CLIENT) {
        return CLIENT_LAYOUT
      }
      return CLIENT_LAYOUT // default
    },
    [isLoggedIn]: (state) => {
      return state.clientSession && state.clientSession.userId > 0
    },
    [defaultPage]: (state) => {
      if (
        state.clientSession &&
        [STAFF, MANAGER].includes(state.clientSession.userRole)
      ) {
        return DEFAULT_ADMIN_PAGE
      }
      return DEFAULT_CLIENT_PAGE // default
    }
  },
  mutations: {
    // ...mapMutations()
    [updateServiceRequest] (state, serviceRequest) {
      state.serviceRequest = serviceRequest
    },
    [setSessionLength] (state, msLength) {
      if (msLength <= msBuffer) {
        msBuffer = getMsBuffer(msLength)
      }
      state.msLength = msLength || defaultMsLength
    },
    [updateSessionTimeout] (state, msLength = undefined) {
      if (!state.clientSession) {
        return
      }

      // Clear existing timeouts
      if (state.timeoutPrompt && state.timeoutPrompt !== null) {
        clearTimeout(state.timeoutPrompt)
      }
      if (state.timeout && state.timeout !== null) {
        clearTimeout(state.timeout)
      }

      // Set session length (default 30 minutes if not specified)
      const defaultSessionLength = 30 * 60 * 1000 // 30 minutes
      state.clientSession.msLength =
        msLength || state.clientSession.msLength || defaultSessionLength

      // Set warning time to 2 minutes before session expiry
      const warningTime = 2 * 60 * 1000 // 2 minutes

      // Calculate when to show the warning prompt
      const timeUntilWarning = state.clientSession.msLength - warningTime

      // Set timeouts
      state.timeoutPrompt = setTimeout(
        launchSessionExpiredPrompt,
        timeUntilWarning
      )
      state.timeout = setTimeout(logOut, state.clientSession.msLength)
    },
    [clearSessionTimeout] (state) {
      clearTimeout(state.timeoutPrompt)
      state.timeoutPrompt = null
      clearTimeout(state.timeout)
      state.timeout = null
    },
    [updateSession] (state, session) {
      if (state.timeoutPrompt && state.timeoutPrompt !== null) {
        clearTimeout(state.timeoutPrompt)
      }
      if (state.timeout && state.timeout !== null) {
        clearTimeout(state.timeout)
      }

      if (state.clientSession) {
        state.clientSession = session
        if (state.clientSession.msLength <= msBuffer) {
          msBuffer = getMsBuffer(state.clientSession.msLength)
        }
        let timeout = state.clientSession.msLength - msBuffer
        state.timeoutPrompt = setTimeout(launchSessionExpiredPrompt, timeout)
        state.timeout = setTimeout(logOut, state.clientSession.msLength)
      }
    },
    [logout] (state) {
      // default session again
      clearTimeout(state.timeoutPrompt)
      state.timeoutPrompt = null
      clearTimeout(state.timeout)
      state.timeout = null

      if (state.clientSession) {
        state.clientSession = {
          siteId: 0,
          userId: 0,
          userRole: CLIENT,
          institutionId: 0,
          msLength: 30 * 1000
        }
      }
    },
    [setActiveSite] (state, site) {
      state.activeSite = site
    }
  },
  actions: {
    // ...mapActions('')
    async updateActiveSite (context) {
      try {
        let route = `/site`
        let response = await Axios.get(route)
        context.commit(setActiveSite, response.data)
      } catch (error) {
      } finally {
      }
    }
  }
})

/*
computed() sate == mapState()
getters for computing values out mapGetters
mutations - simple setters (not async) used with Commit mapMutations
actions - mutations + async code, they commit mutations mapActions
*/
