<template>
  <div
    id="sidebar-area"
    :isOpen="isOpen ? 'true' : 'false'"
    v-if="activeLayout == 'administrative'"
  >
    <div class="menu-control-top" @click="isOpen = !isOpen">
      <i class="fa fa-chevron-circle-left"></i>
    </div>

    <aside
      class="menu"
      v-if="
        (activeLayout == 'dev' || activeLayout == 'administrative') &&
        ![null, 'Login', '2FASetup', '2FAVerify'].includes($route.name)
      "
    >
      <div class="menu-control-bottom" @click="isOpen = !isOpen">
        <div class="fa-svg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
            <path
              d="M400 480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zM252.9 360.4L177.4 288H360c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H177.4l75.5-72.4c9.7-9.3 9.9-24.8.4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L75.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z"
            />
          </svg>
        </div>
        Collapse Menu
      </div>
      <ul class="menu-list">
        <li>
          <nav-link :to="{ name: 'staffDashboard' }" linkClass="has-text-white">
            <span>
              <i class="fas fa-home"></i>
            </span>
            <span> Dashboard </span>
          </nav-link>
        </li>
      </ul>
      <p
        v-if="
          activeLayout == 'administrative' &&
          $hasPermissions(
            clientSession,
            [
              'EMAIL_TEMPLATES',
              'CLIENT_REPORTS',
              'DOCUMENT_CENTER',
              'ANNOUNCEMENTS',,
              'ASSET_REPORT',
              'ASSET_SUMMARY_REPORT',
              'C8925_REPORT',
              'ANNUAL_QUESTIONNAIRE',
              'ANNUAL_BOLI_REVIEW'
            ],
            1
          )
        "
        class="menu-label"
      >
        <span>Operations</span>
      </p>
      <ul class="menu-list">
        <has-permissions :permissions="[['CLIENT_REPORTS', 1]]">
          <li>
            <nav-link :to="{ name: 'Reports' }" linkClass="has-text-white">
              <span style="padding-right: 1px">
                <i class="fa fa-file-alt"></i>
              </span>
              <span> Client Reports </span>
            </nav-link>
          </li>
        </has-permissions>
        <li v-if="$hasPermissions(clientSession, ['ASSET_REPORT', 1])">
          <nav-link :to="{ name: 'AssetReporting' }" linkClass="has-text-white">
            <span style="padding-right: 1px">
              <i class="fa fa-file-alt"></i>
            </span>
            <span> Asset Report </span>
          </nav-link>
        </li>
        <li v-if="$hasPermissions(clientSession, ['ASSET_SUMMARY_REPORT', 1])">
          <nav-link
            :to="{ name: 'AssetSummaryReporting' }"
            linkClass="has-text-white"
          >
            <span style="padding-right: 1px">
              <i class="fa fa-file-alt"></i>
            </span>
            <span> Summary Asset Report </span>
          </nav-link>
        </li>
        <li v-if="$hasPermissions(clientSession, ['C8925_REPORT', 1])">
          <nav-link :to="{ name: '8925Reporting' }" linkClass="has-text-white">
            <span style="padding-right: 1px">
              <i class="fa fa-file-alt"></i>
            </span>
            <span> 8925 Report </span>
          </nav-link>
        </li>
        <li v-if="$hasPermissions(clientSession, ['ANNUAL_QUESTIONNAIRE', 1])">
          <nav-link
            :to="{ name: 'AnnualQuestionnaire' }"
            linkClass="has-text-white"
          >
            <span style="padding-right: 1px">
              <i class="fa fa-file-alt"></i>
            </span>
            <span> Annual Questionnaires </span>
          </nav-link>
        </li>
        <li v-if="$hasPermissions(clientSession, ['ANNUAL_BOLI_REVIEW', 1])">
          <nav-link
            :to="{ name: 'AnnualBOLIReviewListPage' }"
            linkClass="has-text-white"
          >
            <span style="padding-right: 1px">
              <i class="fa fa-file-alt"></i>
            </span>
            <span> Annual {{ coliOrBoli }} Review </span>
          </nav-link>
        </li>
        <li v-if="$hasPermissions(clientSession, ['DOCUMENT_CENTER'], 1)">
          <nav-link
            :to="{ name: 'DocumentsCenterScreen' }"
            linkClass="has-text-white"
          >
            <span>
              <div class="fa-svg">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M0 464C0 490.4 21.6 512 48 512h352c26.4 0 48-21.6 48-48v-192H0V464zM128 368C128 359.1 135.1 352 144 352h160c8.875 0 16 7.125 16 16v40c0 4.375-3.625 8-8 8h-16C291.6 416 288 412.4 288 408V384H160v24C160 412.4 156.4 416 152 416h-16C131.6 416 128 412.4 128 408V368zM400 0h-352C21.6 0 0 21.6 0 48v192h448v-192C448 21.6 426.4 0 400 0zM320 152C320 156.4 316.4 160 312 160h-16C291.6 160 288 156.4 288 152V128H160v24C160 156.4 156.4 160 152 160h-16C131.6 160 128 156.4 128 152V112C128 103.1 135.1 96 144 96h160C312.9 96 320 103.1 320 112V152z"
                  ></path>
                </svg>
              </div>
            </span>
            <span> Document Center </span>
          </nav-link>
        </li>
        <li v-if="$hasPermissions(clientSession, ['EMAIL_TEMPLATES'], 1)">
          <nav-link :to="{ name: 'EmailTemplates' }" linkClass="has-text-white">
            <span style="padding-right: 1px">
              <i
                class="fa fa-envelope"
                style="font-size: 0.9rem !important"
              ></i>
            </span>
            <span> Email Templates </span>
          </nav-link>
        </li>
        <li v-if="$hasPermissions(clientSession, ['ANNOUNCEMENTS'], 1)">
          <nav-link :to="{ name: 'Announcements' }" linklinkClass="has-text-white">
            <span>
              <div class="fa-svg">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M447.1 0h-384c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.1c0 9.75 11.25 15.45 19.12 9.7l124.9-93.7h144c35.25 0 64-28.75 64-63.1V63.1C511.1 28.75 483.2 0 447.1 0zM232 104C232 90.75 242.8 80 256 80s24 10.75 24 24v112c0 13.25-10.75 24-24 24S232 229.3 232 216V104zM256 336c-17.67 0-32-14.33-32-32c0-17.67 14.33-32 32-32s32 14.33 32 32C288 321.7 273.7 336 256 336z"
                  />
                </svg>
              </div>
            </span>
            <span> Announcements </span>
          </nav-link>
        </li>
      </ul>
      <p
        v-if="
          activeLayout == 'administrative' &&
          $hasPermissions(
            clientSession,
            [
              'CARRIERS',
              'INSTITUTIONS',
              'REPORT_TYPES',
              'CLIENT_MENU',
              'SITES',
            ],
            1
          )
        "
        class="menu-label"
      >
        <span>Configuration</span>
      </p>
      <ul class="menu-list">
        <has-permissions :permissions="[['CARRIERS', 1]]">
          <li>
            <nav-link
              :to="{ name: 'CarrierManagementScreen' }"
              linkClass="has-text-white"
            >
              <span>
                <i class="fas fa-briefcase"></i>
              </span>
              <span> Carriers </span>
            </nav-link>
          </li>
        </has-permissions>
        <li v-if="$hasPermissions(clientSession, ['INSTITUTIONS'], 1)">
          <nav-link :to="{ name: 'Institutions' }" linkClass="has-text-white">
            <span>
              <i class="fa fa-university"></i>
            </span>
            <span> Institutions </span>
          </nav-link>
        </li>
        <li v-if="$hasPermissions(clientSession, ['REPORT_TYPES'], 1)">
          <nav-link :to="{ name: 'ReportTypes' }" linkClass="has-text-white">
            <span>
              <i class="fa fa-shapes"></i>
            </span>
            <span> Report Types </span>
          </nav-link>
        </li>
        <li v-if="$hasPermissions(clientSession, ['REPORT_CONFIGURATION'], 1)" class="admin-dropdown">
          <input type="checkbox" id="report-config-dropdown">
          <label class="admin-dropdown-trigger" for="report-config-dropdown">
            <span>
              <i class="fa fa-wrench"></i>
            </span>
            <span> Report Configuration </span>
          </label>
          <div class="admin-dropdown-content">
            <ul>

              <li>
                <has-permissions :permissions="[['ANNUAL_QUESTIONNAIRE', 1]]">
                  <nav-link route="report-configuration" text="State Config">
                    <i class="fa fa-cog"></i>
                  </nav-link>
                </has-permissions>
              </li>

              <li>
                <has-permissions :permissions="[['ANNUAL_QUESTIONNAIRE', 1]]">
                  <nav-link route="report-configuration-annual-questionnaire" text="Annual Questionnaire">
                    <i class="fa fa-cog"></i>
                  </nav-link>
                </has-permissions>
              </li>

              <li>
                <has-permissions :permissions="[['ANNUAL_BOLI_REVIEW', 1]]">
                  <nav-link :route="coliOrBoli + '-Review-Configuration'" :text="'Annual ' + coliOrBoli + ' Review'">
                    <i class="fa fa-cog"></i>
                  </nav-link>
                </has-permissions>
              </li>

            </ul>
          </div>
        </li>
        <li v-if="$hasPermissions(clientSession, ['CLIENT_MENU'], 1)">
          <nav-link
            :to="{ name: 'ClientMenuManagement' }"
            linkClass="has-text-white"
          >
            <span>
              <i class="fa fa-list-ul"></i>
            </span>
            <span> Client Menu </span>
          </nav-link>
        </li>
        <li v-if="$hasPermissions(clientSession, ['SITES'], 1)">
          <nav-link :to="{ name: 'Sites' }" linkClass="has-text-white">
            <span>
              <i class="fa fa-shield-alt"></i>
            </span>
            <span> Sites </span>
          </nav-link>
        </li>
      </ul>
      <p
        v-if="
          activeLayout == 'administrative' &&
          $hasPermissions(
            clientSession,
            ['PERMISSION_TEMPLATES', 'USERS', 'ACTIVITY_REPORT'],
            1
          )
        "
        class="menu-label"
      >
        <span>Security</span>
      </p>
      <ul class="menu-list">
        <li v-if="$hasPermissions(clientSession, ['PERMISSION_TEMPLATES'], 1)">
          <nav-link
            :to="{ name: 'PermissionTemplate' }"
            linkClass="has-text-white"
          >
            <span>
              <i class="fa fa-lock"></i>
            </span>
            <span> Permission Templates </span>
          </nav-link>
        </li>
        <li v-if="$hasPermissions(clientSession, ['USERS'], 1)">
          <nav-link :to="{ name: 'Users' }" linkClass="has-text-white">
            <span style="padding-right: 3px">
              <i class="fa fa-users"></i>
            </span>
            <span> Users </span>
          </nav-link>
        </li>
        <li v-if="$hasPermissions(clientSession, ['ACTIVITY_REPORT'], 1)">
          <nav-link :to="{ name: 'ActivityReport' }" linkClass="has-text-white">
            <span>
              <i class="fa fa-file-contract"></i>
            </span>
            <span> Activity Report </span>
          </nav-link>
        </li>
      </ul>
      <p v-if="activeLayout == 'administrative'" class="menu-label">
        <span>My Account</span>
      </p>
      <ul class="menu-list">
        <li>
          <nav-link :to="{ name: 'MyProfile' }" linkClass="has-text-white">
            <span>
              <i class="fa fa-user-circle"></i>
            </span>
            <span> Profile </span>
          </nav-link>
        </li>
        <li>
          <nav-link :to="{ name: 'Logout' }" linkClass="has-text-white">
            <span style="padding-left: 2px !important">
              <i class="fa fa-sign-out-alt"></i>
            </span>
            <span> Logout </span>
          </nav-link>
        </li>
      </ul>
    </aside>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HasPermissions from './partials/HasPermissions.vue'
import NavLink from './partials/link.vue'
export default {
  components: { HasPermissions, NavLink },
  name: 'sitelinks',
  props: {
    activeLayout: {
      type: String,
      required: true
    }
  },
  watch: {
    isOpen (val) {
      this.$emit('isOpen', val)

      if (!val) {
        document.querySelectorAll('.admin-dropdown').forEach(el => {
          el.querySelector('input').checked = false
        })
      }
    }
  },
  data () {
    return {
      isOpen: true
    }
  },
  computed: {
    ...mapState(['clientSession']),
    coliOrBoli () {
      return this.clientSession.siteId === 1 ? 'BOLI' : 'COLI'
    }
  },
  mounted () {
    this.$nextTick(_ => {
      document.querySelectorAll('.admin-dropdown').forEach(el => {
        el.addEventListener('click', e => {
          if (!this.isOpen) {
            this.isOpen = true
          }
        })
      })
    })
  }
}
</script>

<style>
#sidebar-area[isOpen="true"] {
  position: absolute;
  width: 250px;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #59657a;
}

#sidebar-area[isOpen="false"] {
  position: absolute;
  width: 50px;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #59657a;
  padding: 0 !important;

  transition: width 0.15s ease-in-out;
}

/* headlines with lines */
.menu-label {
  overflow: hidden;
  text-align: center;

  width: 95%;
}

[isOpen="false"] .menu-label > span {
  font-size: 0px;
}

[isOpen="false"] .menu-label {
  position: relative;
  width: 100%;
}

[isOpen="false"] .menu-label span {
  width: 25px;
  border-bottom: 1px solid;
}

[isOpen="false"] .menu-label > span:before,
[isOpen="false"] .menu-label > span:after {
  margin: 0;
  width: 0px;
}

.menu-label > span {
  position: relative;
  display: inline-block;
}
.menu-label > span:before,
.menu-label > span:after {
  content: "";
  position: absolute;
  top: 50%;
  border-bottom: 1px solid;
  width: 100%;
  margin: 0 10px;
}
.menu-label > span:before {
  right: 100%;
}
.menu-label > span:after {
  left: 100%;
}

.menu {
  transition: width 0.15s ease-in-out;
  position: relative;
  height: calc(100% - 52.4px);
  padding: 10px 0px 0px 5px !important;

  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.menu::-webkit-scrollbar {
  display: none;
}

p.menu-label {
  color: #ffffff7c !important;
  font-weight: bold;
}

[isOpen="false"] .menu {
  transition: width 0.15s ease-in-out;
  width: 50px !important;
  padding: 0px !important;
}

[isOpen="false"] .menu-list {
  width: 50px !important;
}

[isOpen="false"] .menu-list li {
  width: 50px !important;
  padding: 0px !important;
  display: flex;
  justify-content: center;
}

[isOpen="false"] .admin-dropdown span:nth-of-type(2) {
  display: none;
}

[isOpen="false"] .admin-dropdown {
  margin-left: .35rem;
}

[isOpen="false"] .menu-list a {
  font-size: 0px;
  width: 50px !important;
  padding: 0.5rem 0px !important;

  display: flex;
  justify-content: center !important;
}

[isOpen="false"] a i {
  padding: 0px !important;
}

[isOpen="false"] .fa.fa-file-alt,
[isOpen="false"] .fa.fa-file-contract {
  padding-left: 2px !important;
}

ul.menu-list li a .fa,
ul.menu-list li a .fas,
ul.menu-list li a div {
  margin-right: 0.75rem;
  width: 0.75rem;
}

[isOpen="false"] ul.menu-list li a .fa,
ul.menu-list li a .fas,
ul.menu-list li a div {
  margin-right: 0.75rem;
  width: 100%;
}

[isOpen="false"] ul.menu-list li a,
ul.menu-list li a,
ul.menu-list li a {
  width: 100% !important;

  display: flex;
  justify-content: center !important;
}

ul.menu-list li a {
  color: white !important;
  padding-left: 3px;
  font-weight: normal;

  width: 100%;

  display: flex;
  justify-content: flex-start !important;

  height: 100%;
}
ul.menu-list li a:nth-child(1) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
ul.menu-list li a span {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
ul.menu-list .sublist {
  font-weight: bold;
  color: white;
}
ul.menu-list li ul {
  padding-left: 0px !important;
  border: 0px none !important;
  margin-left: 6px;
}

.modal .modal-card {
  z-index: 1050!important;
}

.modal .modal-background {
  z-index: 1000!important;
}

.dialog.modal.is-active {
  z-index: 999999!important;
}

.dialog.modal.is-active + div {
  display: none!important;
}

[isOpen="true"] ul.menu-list li a:hover {
  background-color: cornflowerblue;
  color: white !important;
}

[isOpen="false"] ul.menu-list li a:hover {
  background-color: #59657a;
  color: rgba(169, 169, 170, 0.573) !important;
}

[isOpen="false"] .menu-control-top i {
  transform: rotate(-180deg);
  transition: all 0.5s ease-in-out;
}

[isOpen="false"] .menu-control-bottom div.fa-svg {
  transform: rotate(-180deg);
  transition: all 0.5s ease-in-out;
  margin-right: 5px !important;
}

[isOpen="false"] .menu-control-bottom {
  font-size: 0;
  margin: 0 auto;
  left: 0px;

  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
}

[isOpen="true"] .menu-control-bottom div.fa-svg {
  margin: 0;
  margin-bottom: -5px;
  margin-right: 5px !important;

  transform: rotate(0deg);
  transition: transform 0.15s ease-in-out;
}

.menu-control-top {
  transition: all 0.15s ease-in-out;
  position: absolute;

  top: 0.2rem;
  right: -0.75rem;

  width: 25px;
  height: 25px;

  display: flex;
  justify-content: center !important;
  align-items: center !important;

  color: white !important;
  background-color: #59657a !important;
  z-index: 900 !important;

  cursor: pointer;

  border-radius: 1000px;
  border: 2px solid transparent !important;
}

.menu-control-top i {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  transition: all 0.5s ease-in-out;
}

.menu-control-top i.fa {
  font-size: 1.3rem !important;
  margin: 0px;
}

.menu-control-bottom i.fas {
  transition: all 0.5s ease-in-out;
  font-size: 1.2rem !important;
  margin-right: 1rem;
}

[isOpen="false"] .menu-control-bottom {
  width: 40px;
}

.menu-control-bottom {
  transition: width 0.15s ease-in-out;
  position: fixed;
  bottom: 0.6rem;
  left: 0;

  width: auto;

  width: 245px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 0.5rem;

  font-size: 0.75rem;

  color: white;
  z-index: 1000 !important;

  cursor: pointer;
  z-index: 50 !important;
}

.fa-svg {
  width: 0.95rem !important;
  fill: white;
  margin-left: 1rem;
}

ul.menu-list li a span:first-of-type {
  width: 35px;
  height: 20px;

  display: flex;
  flex-direction: row !important;
  justify-content: flex-end !important;
  align-items: center !important;

  position: relative;
}

ul.menu-list li a span:first-of-type div.fa-svg {
  width: 13.5px !important;
  height: 13.5px !important;

  position: absolute;
}

ul.menu-list li a span:first-of-type :first-child svg {
  display: inline-block;
  width: 13px;
}

/* .fa-svg:hover {
  fill: #878c95!important;
} */
</style>
